<template>
    <div class="module flex-col">
        <div class="w-full flex justify-between items-center">
            <div class="box-title flex flex-col">Avance real trámites<div class="legend">{{data.name}}</div></div>            
            <Legend :colorMain="'#79849A'" :colorSecondary="'#A279E2'" :legendSecondary="'Avance Real'" />
        </div>
        <div class="w-full flex-1 h-32">
            <template v-if="loading">
                <div class="w-full h-full flex justify-center items-center">
                    <img class="loader" src="/img/loader.svg" />
                </div>
            </template>
            <template v-else>
                <AreaSimpleChart :serie1="data.evolution" :name2="'Avance Real'" :chartId="'chartId'" :areaColor="'#A279E2'" :lineColor="'#A279E2'" />
            </template>
        </div>
    </div>
</template>

<script>
import AreaSimpleChart from '@/components/charts/AreaSimpleChart.vue';
import Legend from '@/components/charts/Legend.vue';
export default {
    name: 'FormalitiesChart',
    components: {
        AreaSimpleChart,
        Legend,
    },
    props: ['data','loading'],
    data() {
        return {
            //loading: true,
        }
    }
}
</script>

<style>

</style>