var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-full flex flex-col"},[_c('All',{key:'BuildingGeneral',attrs:{"model":"BuildingGeneralBuilding","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.building_general},on:{"update:data":function($event){_vm.building_general=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return undefined}}])}),_c('div',{staticClass:"module"},[_c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-4/12 xl:w-3/12 pl-2 pr-1 flex flex-col"},[_c('All',{key:'BuildingAcumulated',attrs:{"model":"BuildingAcumulated","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.building_acumulated},on:{"update:data":function($event){_vm.building_acumulated=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('ConstructionPie',{attrs:{"data":_vm.building_acumulated,"loading":loading}})]}}])}),_c('All',{key:'BuildingImages',attrs:{"model":"BuildingImages","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.building_images},on:{"update:data":function($event){_vm.building_images=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('ConstructionBIM',{attrs:{"data":_vm.building_images,"loading":loading}})]}}])})],1),_c('div',{staticClass:"w-6/12 pl-0 pr-2 flex flex-col"},[_c('All',{key:'BuildingLots',attrs:{"model":"BuildingLots","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.building_lots},on:{"update:data":function($event){_vm.building_lots=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('ConstructionLotes',{attrs:{"data":_vm.building_lots,"loading":loading}})]}}])})],1),_c('div',{staticClass:"w-2/12 xl:w-3/12 px-1 h-full flex flex-col justify-start xl:justify-between"},[_c('All',{key:'BuildingTimeline',attrs:{"model":"BuildingTimeline","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.building_timeline},on:{"update:data":function($event){_vm.building_timeline=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('ConstructionTimeline',{attrs:{"data":_vm.building_timeline,"loading":loading}})]}}])}),_c('All',{key:'BuildingGeneralKpis',attrs:{"model":"BuildingGeneralKpis","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.building_general_kpis},on:{"update:data":function($event){_vm.building_general_kpis=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('ConstructionKpi',{attrs:{"data":_vm.building_general_kpis,"loading":loading}})]}}])})],1)])]),_c('All',{key:'BuildingEvolutionaries',attrs:{"model":"BuildingEvolutionaries","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.building_evolutionaries},on:{"update:data":function($event){_vm.building_evolutionaries=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('ConstructionChart',{attrs:{"data":_vm.building_evolutionaries,"loading":loading}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }