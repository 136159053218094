<template>
    <div class="relative chart-wrap">
        <highcharts :class="pieOptions.classname" :options="options" :ref="id" :id="id"></highcharts>
    </div>
</template>

<script>

export default {
    name: 'GaugeChart',
    props: ['data','pieOptions','id','color','name','secondData','secondName','secondColor'],
    computed: {
        options() {
            return  {
                chart: {
                    type: 'solidgauge',
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    borderWidth:0,
                    backgroundColor: 'rgba(255,255,255,0)',
                    margin: [0,0,0,0],
                    spacingTop: 0,
                    spacingBottom: 0,
                    spacingRight: 0,
                    spacingLeft: 0,
                },
                title: {
                    text: '',
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 40
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                // pane: {
                //     startAngle: 0,
                //     endAngle: 360,
                //     background: {
                //         outerRadius: '100%',
                //         innerRadius: '85%',
                //         backgroundColor: '#2E3342',
                //         borderWidth: 0,
                //         shape: 'arc'
                //     },
                // },
                // yAxis: {
                //     min: 0,
                //     max: 100,
                //     lineWidth: 0,
                //     tickPositions: []
                // },
            
                pane: [
                    {
                        startAngle: 1,
                        endAngle: 359,
                        background: {
                            outerRadius: '100%',
                            innerRadius: '85%',
                            backgroundColor: '#2E3342',
                            borderWidth: 0,
                            shape: 'arc'
                        },
                    },
                    {
                        startAngle: 1,
                        endAngle: 359,
                        background: {
                            outerRadius: '80%',
                            innerRadius: '65%',
                            backgroundColor: (this.secondData) ? '#2E3342' : 'transparent',
                            borderWidth: 0,
                            shape: 'arc'
                        },
                    }
                ],
                yAxis: [
                    {
                        pane: 0,
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: []
                    },
                    {
                        pane: 1,
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: []
                    }
                ],
                tooltip: {
                    // positioner: function () {
                    //     return { x: 0, y: 0 };
                    // },
                    style: {
                        color: '#79849A',
                        //fontSize: '10px',
                    },
                    borderRadius: 20,
                    pointFormat: '<b>{series.name}</b><br/><b>{point.y}%</b>',
                },
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            enabled: false
                        },
                        linecap: 'round',
                        stickyTracking: false,
                        rounded: false
                    },
                    series: {
                        animation: {
                            duration: 5000
                        }
                    }
                },
                series: [
                    {
                        name: this.name,
                        yAxis: 1,
                        data: [{
                            radius: 100,
                            innerRadius: 85,
                            y: this.data,
                            color: this.color,
                        }],
                    },{
                        name: this.secondName,
                        yAxis: 0,
                        data: [{
                            radius: 80,
                            innerRadius: 65,
                            y: this.secondData,
                            color: this.secondColor,
                        }],
                    }
                ]
            }
        }
    },
};
</script>