<template>
    <div class="box mb-2 flex-grow">
        <div class="box-title">Hitos</div>
        <div class="flex flex-col h-56 xl:h-64 overflow-auto flex-grow">
            <template v-if="loading">
                <div class="w-full h-full flex justify-center items-center">
                    <img class="loader" src="/img/loader.svg" />
                </div>
            </template>
            <template v-else>
                <div v-for="event in data.events" :key="event.id" class="bg-area rounded-md p-2 mb-2">
                    <div class="w-full flex justify-between items-center mb-1">
                        <div class="w-auto mb-1 xl:mb-0 px-1 flex items-center rounded-xl border bg-opacity-25 font-medium" :class="priority(event.priority)"> <!--:class="priority(event.priority)"-->
                            <span class="text-xxs">{{event.priority}}</span>
                        </div>
                        <div class="flex-1 flex flex-col items-end justify-center">
                            <div class="w-full flex justify-end items-center leading-none">
                                <div class="w-auto legend-xs text-center xl:text-right flex items-center"><span class="mdi mdi-calendar text-grayText text-sm mr-1"></span>{{event.date | moment("DD/MM/YY")}}</div>

                                <div class="w-auto legend-xs text-center xl:text-right flex items-center"><span class="mdi mdi-arrow-right text-grayText text-sm mx-1 opacity-50"></span>{{event.end_date | moment("DD/MM/YY")}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full flex flex-col justify-between items-start mb-2">
                        <div class="w-auto px-1 flex items-center rounded-xl">
                            <!-- <span class="w-3 h-3 bg-bad rounded-full mr-3"></span> -->
                            <span class="font-medium text-xs xl:text-sm text-white leading-tight">{{event.action}}</span>
                        </div>
                        <div class="w-full legend-xs leading-none pl-1 truncate">{{event.responsible}}</div>
                        <!-- <div class="w-full xl:w-4/12 legend-xs flex items-center justify-start xl:justify-end"><span class="mdi mdi-calendar mr-2 text-lg"></span>{{event.date | moment("DD/MM/YY")}}</div> -->
                    </div>

                    <div class="w-full flex justify-around px-1">
                        <div class="w-1/2 flex flex-col">
                            <div class="legend-xs">Inversión</div>
                            <div class="flex">
                                <span class="text-xs xl:text-base text-white">{{event.inversion | numFormat('0,0')}}€</span>
                            </div>
                        </div>
                        <div class="w-1/2 flex flex-col">
                            <div class="legend-xs">Desviación</div>
                            <div class="flex">
                                <span class="text-xs xl:text-base text-white">{{event.deviation_percent | numFormat('0.0')}}%</span>
                                <span class="text-xs xl:text-base mx-2" :class="status(event.deviation)">{{event.deviation | numFormat('0,0')}}€</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InvestmentTimeline',
    props: ['data','loading'],
    methods: {
        status(status) {
            return {
                'text-bad' : status > 0,
                // 'border-regular bg-regular text-regular' : status == 'regular',
                'text-good' : status <= 0,
            }
        },
        priority(priority) {
            return {
                'border-bad text-bad bg-bad' : priority == 'ALTA',
                'border-phase2 text-phase2 bg-phase2' : priority == 'MEDIA',
                'border-phase1 text-phase1 bg-phase1' : priority == 'BAJA',
            }
        }
    },
}
</script>

<style>

</style>