<template>
    <div class="w-full flex -mx-2">
        <div class="w-full box mb-2">
            <template v-if="loading">
                <div class="w-full flex justify-center items-center" style="height: 24rem;">
                    <img class="loader" src="/img/loader.svg" />
                </div>
            </template>
            <template v-else>
                <div class="w-full relative" style="height: 16rem; min-height: 16rem;">
                    <div class="absolute inset-0 flex flex-col justify-center items-center mt-6">
                        <span class="legend-xs">Acum. total</span>
                        <span class="flex flex-col items-center leading-none w-24 border-b-2 border-area pb-1">
                            <span class="text-phase3 text-lg xl:text-xl font-medium">{{data.acumulated_total | numFormat('0,00')}}<span class="symbol">€</span></span>
                            <span class="text-white font-medium text-sm leading-none">{{data.deviation_percent | numFormat('0.00')}}<span class="symbol">%</span></span>
                        </span>

                        <span class="legend-xs">Semana {{data.week}}</span>
                        <span class="font-medium text-xs xl:text-sm leading-none text-blue">{{data.acumulated_week | numFormat('0,00')}}<span class="symbol">€</span></span>
                        <span class="text-white font-medium text-xs xl:text-sm leading-none">{{data.acumulated_percent | numFormat('0.00')}}<span class="symbol">%</span></span>
                    </div>
                    <div class="absolute inset-0 m-0 z-50 mx-auto h-72 w-auto">
                        <GaugeChart :pieOptions="pieOptions" :data="data.deviation_percent" :secondData="data.acumulated_percent" :name="'Acumulado semana'" :secondName="'Semana ' + data.week" :id="'invest-gauge-week'" :color="'#6ECAA4'" :secondColor="'#415CF7'" />
                    </div>
                </div>
                <div class="w-full h-44 pb-6 mt-4">
                    <template v-if="data.evolution_chart">
                        <LineChart :data="data.evolution_chart" :chartId="'chartId'" :color="'#415CF7'" />
                        <span class="legend-xs -mt-2 opacity-50">Semanas</span>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import GaugeChart from '@/components/charts/GaugeChart.vue';
import LineChart from '@/components/charts/LineChart.vue';
export default {
    name: 'ConstructionPie',
    components: {
        GaugeChart,
        LineChart,
    },
    props: ['data','loading'],
    data() {
        return {
            pieOptions: {
                width:'95%',
                classname: 'pie-chart'
            },
        }
    }
}
</script>