<template>
    <div class="w-full h-full flex flex-col">
        <All
            model="InvestmentControlGeneralInvestment"
            :key="'InvestmentGeneral'"
            :immediate="true"
            :sensitive="true"
            :query="query"
            :data.sync="investment_general"
            v-slot="{ loading }">
        </All>


        <div class="module flex-grow">
            <div class="w-full flex h-full">
                <div class="w-8/12 px-2 flex flex-col h-full">
                    <All
                        model="InvestmentControlInvestmentBilled"
                        :key="'InvestmentPie'"
                        :immediate="true"
                        :sensitive="true"
                        :query="query"
                        :data.sync="investment_pie"
                        v-slot="{ loading }">
                        <InvestmentPie :data="investment_pie" :loading="loading" />
                    </All>
                    <All
                        model="InvestmentControlInvestmentApproveds"
                        :key="'InvestmentKpi'"
                        :immediate="true"
                        :sensitive="true"
                        :query="query"
                        :data.sync="investment_kpi"
                        v-slot="{ loading }">
                        <InvestmentKpi :data="investment_kpi" :loading="loading"  />
                    </All>
                </div>
                <div class="w-4/12 px-2 flex flex-col">
                    <All
                        model="InvestmentControlInvestmentTimeline"
                        :key="'InvestmentTimeline'"
                        :immediate="true"
                        :sensitive="true"
                        :query="query"
                        :data.sync="investment_timeline"
                        v-slot="{ loading }">
                        <InvestmentTimeline :data="investment_timeline" :loading="loading"  />
                    </All>
                    <All
                        model="InvestmentControlInvestmentAlerts"
                        :key="'InvestmentAlerts'"
                        :immediate="true"
                        :sensitive="true"
                        :query="query"
                        :data.sync="investment_alerts"
                        v-slot="{ loading }">
                        <InvestmentAlerts :data="investment_alerts" :loading="loading"  />
                    </All>
                </div>
            </div>
        </div>
        <All
            model="InvestmentControlEvolutionaries"
            :key="'InvestmentChart'"
            :immediate="true"
            :sensitive="true"
            :query="query"
            :data.sync="investment_chart"
            v-slot="{ loading }">
            <InvestmentChart :data="investment_chart" :loading="loading" />
        </All>
    </div>
</template>

<script>

import InvestmentChart from '@/components/investment/InvestmentChart.vue';
import InvestmentPie from '@/components/investment/InvestmentPie.vue';
import InvestmentTimeline from '@/components/investment/InvestmentTimeline.vue';
import InvestmentKpi from '@/components/investment/InvestmentKpi.vue';
import InvestmentAlerts from '@/components/investment/InvestmentAlerts.vue';
import { All } from '@/api/components';
import { actions } from '@/store';

export default {
    name: 'Investment',
    components: {
       InvestmentChart,
       InvestmentPie,
       InvestmentTimeline,
       InvestmentKpi,
       InvestmentAlerts,
       All
    },
    data() {
        return {
            investment_general: {},
            investment_kpi: {},
            investment_chart: {},
            investment_pie: {},
            investment_timeline: {},
            investment_alerts: {},
        };
    },
    computed: {
        projectId() {
            return this.$route.query.id;
        },
        excelId() {
            return this.$route.query.excel;
        },
        query() {
            return {
                projectId: this.projectId,
                excelId: this.excelId
            }
        },
    },
    watch: {
        investment_general(investment_general) {
            actions.setRatios(investment_general);
        }
    },
    mounted() {
        actions.setDashboard('investment');
    },
}
</script>

<style>

</style>
