<template>
    <div class="w-full flex my-1 h-20">
        <div class="w-48 bg-area p-2 rounded-md mr-1">
            <div class="text-white">Licencia 1</div>
        </div>
        <div class="w-auto flex-1 bg-box p-2 rounded-md">
            <RangeChart :id="'range-chart'" />
        </div>
    </div>
</template>

<script>
import RangeChart from '@/components/charts/RangeChart.vue';
export default {
    name: 'FormalitiesRow',
    components: {
        RangeChart,
    },
}
</script>

<style>

</style>