<template>
    <div class="module flex-col justify-between h-full extra:h-36 cursor-pointer w-full border-2 transition" :class="{'border-main opacity-100' : dashboard == 'investment', 'border-module opacity-50' : dashboard != 'investment'}">
        <div class="w-full flex justify-center extra:justify-start items-center">
            <!-- <div class="hidden extra:flex w-3 h-3 bg-bad rounded-full mr-3"></div> -->
            <div class="hidden extra:flex font-medium text-grayText">Control Inversión</div>
            <div class="flex extra:hidden mdi mdi-cash-multiple text-grayText text-2xl"></div>
        </div>
        <div class="w-full flex flex-col items-center py-1 leading-none">
            <div class="text-white font-medium text-sm extra:text-2xl leading-none pt-1">{{data.acumulado | numFormat('0,0')}}€</div>
            <div class="legend-xs text-center py-2">Total inversión</div>
        </div>
        <div class="w-full flex flex-col extra:flex-row justify-between items-center py-2 bg-box rounded-md px-2">
            <div class="legend-xs">Desviación</div>
            <div class="text-main text-xs xl:text-lg leading-none extra:py-0 py-2">{{data.deviation_percent | numFormat('0.0')}}%</div>
            <div class="text-white text-xs xl:text-lg leading-none">{{data.deviation | numFormat('0,0')}}€</div>
        </div>
    </div>
</template>

<script>
import { state } from '@/store';
export default {
    name: 'NavInvestment',
    props: ['data'],
    computed: {
        dashboard() {
            return state.dashboard;
        },
    },
}
</script>

<style>

</style>