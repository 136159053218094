import Vue from 'vue';
import { toCookie, toLocal } from './storages';

const store = {
    token: false,
    user: false,
    ratios: {},
    dashboard: '',
    investment_resume: {},
    formalities_resume: {},
    construction_resume: {},
};

toCookie(store, {
    attrs: ['token','dashboard']
});

const state = Vue.observable(store);

const actions = {
    setToken(token) {
        state.token = token;
    },
    setUser(user) {
        state.user = user;
    },
    resetAuth() {
        state.token = false;
        state.user = false;
    },
    setRatios(value){
        state.ratios = value;
    },
    setInvestment(value){
        state.investment_resume = value;
    },
    setFormalities(value){
        state.formalities_resume = value;
    },
    setConstructionKpis(value){
        state.construction_resume = value;
    },
    setDashboard(value){
        state.dashboard = value;
    }
};

export {
    state,
    actions
};