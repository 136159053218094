<template>
    <div class="text-white w-screen h-screen bg-body bg-opacity-50 z-50 flex justify-center items-center fixed inset-0">
        <div class="module w-1/3 p-8 flex flex-col">
          <div class="w-full cursor-pointer flex justify-end focus:outline-none"
              @click="closeModal()"
              content="Cerrar" v-tippy="{followCursor : true, placement : 'top', animation: 'scale'}">
              <span class="text-grayText text-3xl mdi mdi-close-circle-outline"></span>
          </div>
          <div class="px-8 pb-8">
              <img :src="data" class="w-full h-full object-cover"/>
          </div>
        </div>
    </div>
</template>

<script>
export default {
   props: ['data'],
   methods: {
         closeModal(image) {
             this.$emit('closeModal');
         }   
    }
}
</script>

<style>

</style>