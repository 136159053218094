<template>
    <div class="w-full flex h-40 my-4">
        <template v-if="loading">
            <div class="w-full h-full flex justify-center items-center">
                <img class="loader" src="/img/loader.svg" />
            </div>
        </template>
        <template v-else>
            <div class="w-9/12 -mx-1 flex flex-wrap">
                <div v-for="kpi in data.kpis" :key="kpi.id" class="w-1/3 px-1 mb-2 flex align-center justify-center">
                    <div class="bg-box rounded-md flex flex-col items-center px-2 py-4 w-full justify-center">
                        <div class="legend-xs mb-1 text-center">{{ kpi.name }}</div>
                        <div class="text-white font-medium text-md xl:text-2xl leading-none">{{ kpi.value | numFormat('0,0') }} <span class="symbol">€</span></div>
                    </div>
                </div>
            </div>
            <div class="w-3/12 mx-2 flex flex-grow">
                <div class="w-full h-full px-1 mb-2">
                    <div class="bg-opacity-25 border-2 rounded-md flex h-full flex-col justify-center items-center p-2 flex-1" :class="status(data.deviation)">
                        <div class="text-sm">Desviación</div>
                        <div class="text-white font-medium text-md xl:text-2xl">{{ data.deviation | numFormat('0,0') }} <span class="symbol">€</span></div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'InvestmentKpi',
    props: ['data','loading'],
    methods: {
        status(status) {
            return {
                'border-bad bg-bad text-bad' : status > 0,
                // 'border-regular bg-regular text-regular' : status == 'regular',
                'border-good bg-good text-good' : status <= 0,
            }
        },

    },
}
</script>

<style>

</style>