<template>
    <div class="w-full flex -mx-2 flex-grow overflow-hidden">
        <div class="w-full box h-auto mb-2 relative flex justify-center items-center flex-1">
            <template v-if="loading">
                <div class="w-full h-48 flex justify-center items-center">
                    <img class="loader" src="/img/loader.svg" />
                </div>
            </template>
            <template v-else>
                <div class="z-50 rounded-lg z-50 cursor-pointer flex flex-wrap items-center justify-center">
                    <img v-for="image in data.images" v-bind:key="image" :src="image" @click="openModal(image)" class="w-1/3 h-20 p-1 object-cover rounded-lg shadow-lg " />
                </div>
                <div class="z-30 w-full h-full cursor-pointer max-h-12r" @click="openModal(data.image_one)">
                    <img :src="data.image_one" class="object-cover w-full h-full rounded-lg p-1" />
                </div>
            </template>
        </div>
        <portal v-if="modal" to="modal">
            <transition name="slide-fade" mode="out-in" appear>
                <BimModal :data="image" @closeModal="closeModal"  />
            </transition>
        </portal>
    </div>
</template>

<script>
import GaugeChart from '@/components/charts/GaugeChart.vue';
import LineChart from '@/components/charts/LineChart.vue';
import BimModal from '@/components/construction/BimModal.vue';
export default {
    name: 'ConstructionBIM',
    components: {
        GaugeChart,
        LineChart,
        BimModal,
    },
    props: ['data','loading'],
    data() {
        return {
            pieOptions: {
                width:'95%',
                classname: 'pie-chart'
            },
            modal: false,
            image: '',
        }

    },
    methods: {
         openModal(image) {
             this.modal = !this.modal;
             this.image = image;
         },
         closeModal() {
             this.modal = !this.modal;
             this.image = '';
         }
    }
}
</script>

<style>

</style>