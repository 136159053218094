<template>
    <div class="w-full flex flex-col justify-between items-center extra:mx-2 mx-1">
        <div class="w-full xl:w-10/12 legend capitalize">{{data.name}}</div>
        <div class="w-full xl:w-10/12 bg-box rounded-md py-1 px-2 flex justify-center items-center">
            <div v-if="dashboard == 'investment'"class="legend-xs">{{data.value_real | numFormat('0')}} / {{data.value_reference | numFormat('0')}}</div>
            <div v-else class="legend-xs">{{data.value | numFormat('0')}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Ratio',
    props: ['data','dashboard'],
}
</script>

<style>

</style>