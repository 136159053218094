<template>
    <div class="module flex-col">
        <div class="w-full flex justify-between items-center">
            <div class="box-title">Gastos vs. Previsión</div>
            <Legend :colorMain="'#F0961A'" :colorSecondary="'#A279E2'" :legendMain="'Previsión'" :legendSecondary="'Gasto'" />
        </div>
        
        <div class="w-full flex-1 h-32">
            <template v-if="loading">
                <div class="w-full h-full flex justify-center items-center">
                    <img class="loader" src="/img/loader.svg" />
                </div>
            </template>
            <template v-else>
                <AreaChart :serie1="data.prevision" :serie2="data.spending" :name1="'Previsión'" :name2="'Gasto'" :chartId="'chartId'" :areaColor="'#A279E2'" :lineColor="'#F0961A'" :symbol="'€'" />
            </template>
        </div>
    </div>
</template>

<script>
import AreaChart from '@/components/charts/AreaChart.vue';
import Legend from '@/components/charts/Legend.vue';
export default {
    name: 'ConstructionChart',
    props: ['data','loading'],
    components: {
        AreaChart,
        Legend,
    }
}
</script>

<style>

</style>