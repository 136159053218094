<template>
    <div class="module flex-col justify-between h-full extra:h-36 cursor-pointer w-full border-2 transition" :class="{'border-main opacity-100' : dashboard == 'construction', 'border-module opacity-50' : dashboard != 'construction'}">
        <div class="w-full flex justify-center extra:justify-start items-center">
            <!-- <div class="hidden extra:flex w-3 h-3 bg-good rounded-full mr-3"></div> -->
            <div class="hidden extra:flex font-medium text-grayText">Construcción</div>
            <div class="flex extra:hidden mdi mdi-tools text-grayText text-2xl"></div>
        </div>
        <div class="w-full flex flex-wrap justify-center extra:justify-start items-center">
            <div class="extra:w-1/3 w-1/2 flex flex-col items-center justify-center mb-1 extra:mb-0 p-1">
                <div class="text-white font-medium text-sm lg:text-md xl:text-xl leading-none">{{lotes.definition_percent | numFormat('0.0') }}<span class="symbol">%</span></div>
                <div class="text-xxs xl:text-xs text-phase1 pt-1">Definición</div>
            </div>
            <div class="extra:w-1/3 w-1/2 flex flex-col items-center justify-center mb-1 extra:mb-0 p-1">
                <div class="text-white font-medium text-sm lg:text-md xl:text-xl leading-none">{{lotes.hiring_percent | numFormat('0.0') }}<span class="symbol">%</span></div>
                <div class="text-xxs xl:text-xs text-phase2 pt-1">Contratación</div>
            </div>
            <div class="extra:w-1/3 w-1/2 flex flex-col items-center justify-center mb-1 extra:mb-0 p-1">
                <div class="text-white font-medium text-sm lg:text-md xl:text-xl leading-none">{{lotes.execution_percent | numFormat('0.0') }}<span class="symbol">%</span></div>
                <div class="text-xxs xl:text-xs text-phase3 pt-1">Ejecución</div>
            </div>
        </div>
        <div class="flex flex-wrap extra:flex-row items-center bg-box rounded-md py-1 extra:py-0 px-0 extra:px-2">
            <div class="extra:w-1/4 w-1/2 f-center my-0 extra:my-1">
                <div class="mdi mr-1 mdi-security text-sm extra:text-base text-grayText"></div>
                <div class="text-main font-medium text-xs leading-none">{{data.security | numFormat('0')}}%</div>
            </div>
            <div class="extra:w-1/4 w-1/2 f-center my-0 extra:my-1">
                <div class="mdi mr-1 mdi-shield-check text-sm extra:text-base text-grayText"></div>
                <div class="text-main font-medium text-xs leading-none">{{data.quality | numFormat('0')}}%</div>
            </div>
            <div class="extra:w-1/4 w-1/2 f-center my-0 extra:my-1">
                <div class="mdi mr-1 mdi-bottle-tonic-plus text-sm extra:text-base text-grayText"></div>
                <div class="text-main font-medium text-xs leading-none">{{data.risk | numFormat('0')}}%</div>
            </div>
            <div class="extra:w-1/4 w-1/2 f-center my-0 extra:my-1">
                <!-- <div class="mdi mdi-earth text-main text-md"></div> -->
                <div class="mdi mr-2 mdi-account text-sm extra:text-base text-grayText"></div>
                <div class="text-main font-medium text-xs leading-none">{{data.workers | numFormat('0')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { state } from '@/store';
export default {
    name: 'NavConstruction',
    props: ['data','lotes'],
    computed: {
        dashboard() {
            return state.dashboard;
        },
    },
}
</script>

<style>

</style>