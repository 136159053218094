<template>
    <div id="app" class="w-screen h-screen text-gray-900 bg-body">
        <!-- <Layout class="w-full min-h-full">
            
        </Layout> -->
        <router-view/>
        <portal-target name="modal"></portal-target>
    </div>
</template>

<script>
    import { Layout } from '@/layouts';

    export default {
        name: 'App',
        components: {
            Layout
        }
    };
</script>