<template>
    <div class="w-full h-full flex flex-col">
        <All
            model="BuildingGeneralBuilding"
            :key="'BuildingGeneral'"
            :immediate="true"
            :sensitive="true"
            :query="query"
            :data.sync="building_general"
            v-slot="{ loading }">
        </All>
        <div class="module">
            <div class="w-full flex">
                <div class="w-4/12 xl:w-3/12 pl-2 pr-1 flex flex-col">
                    <All
                        model="BuildingAcumulated"
                        :key="'BuildingAcumulated'"
                        :immediate="true"
                        :sensitive="true"
                        :query="query"
                        :data.sync="building_acumulated"
                        v-slot="{ loading }">
                        <ConstructionPie :data="building_acumulated" :loading="loading" />
                    </All>
                    <All
                        model="BuildingImages"
                        :key="'BuildingImages'"
                        :immediate="true"
                        :sensitive="true"
                        :query="query"
                        :data.sync="building_images"
                        v-slot="{ loading }">
                        <ConstructionBIM :data="building_images" :loading="loading" />
                    </All>
                </div>
                <div class="w-6/12 pl-0 pr-2 flex flex-col">
                    <All
                        model="BuildingLots"
                        :key="'BuildingLots'"
                        :immediate="true"
                        :sensitive="true"
                        :query="query"
                        :data.sync="building_lots"
                        v-slot="{ loading }">
                        <ConstructionLotes :data="building_lots" :loading="loading" />
                    </All>
                </div>
                <div class="w-2/12 xl:w-3/12 px-1 h-full flex flex-col justify-start xl:justify-between">
                    <All
                        model="BuildingTimeline"
                        :key="'BuildingTimeline'"
                        :immediate="true"
                        :sensitive="true"
                        :query="query"
                        :data.sync="building_timeline"
                        v-slot="{ loading }">
                        <ConstructionTimeline :data="building_timeline" :loading="loading" />
                    </All>
                    <All
                        model="BuildingGeneralKpis"
                        :key="'BuildingGeneralKpis'"
                        :immediate="true"
                        :sensitive="true"
                        :query="query"
                        :data.sync="building_general_kpis"
                        v-slot="{ loading }">
                        <ConstructionKpi :data="building_general_kpis" :loading="loading" />
                    </All>
                </div>
            </div>
        </div>
        <All
             model="BuildingEvolutionaries"
            :key="'BuildingEvolutionaries'"
            :immediate="true"
            :sensitive="true"
            :query="query"
            :data.sync="building_evolutionaries"
             v-slot="{ loading }">
            <ConstructionChart :data="building_evolutionaries" :loading="loading" />
        </All>
    </div>
</template>

<script>
import ConstructionPie from '@/components/construction/ConstructionPie.vue';
import ConstructionTimeline from '@/components/construction/ConstructionTimeline.vue';
import ConstructionChart from '@/components/construction/ConstructionChart.vue';
import ConstructionKpi from '@/components/construction/ConstructionKpi.vue';
import ConstructionLotes from '@/components/construction/ConstructionLotes.vue';
import ConstructionBIM from '@/components/construction/ConstructionBIM.vue';
import { All } from '@/api/components';
import { actions } from '@/store';
export default {
    name: 'Construction',
    components: {
       ConstructionPie,
       ConstructionTimeline,
       ConstructionChart,
       ConstructionKpi,
       ConstructionLotes,
       ConstructionBIM,
       All,
    },
    data() {
        return {
            building_general: {},
            building_images: {},
            building_acumulated: {},
            building_lots: {},
            building_timeline: {},
            building_general_kpis: {},
            building_evolutionaries: {},
        }
    },
    computed: {
        projectId() {
            return this.$route.query.id;
        },
        excelId() {
            return this.$route.query.excel;
        },
        query() {
            return {
                projectId: this.projectId,
                excelId: this.excelId
            }
        },
    },
    watch: {
        building_general(building_general) {
            actions.setRatios(building_general);
        },
        building_general_kpis(building_general_kpis) {
            actions.setConstructionKpis(building_general_kpis);
        },
    },
}
</script>

<style>

</style>