<template>
  <div class="module w-full flex items-center py-4 divide-x-2 divide-area">
        <div class="w-6/12 xl:w-5/12 h-full flex items-center ">
            <div>
                <img class="w-24 h-12 object-contain" :src="project.image" />
            </div>
            <div class="flex-1 flex-col mx-4 overflow-hidden">
                <div class="text-white font-bold text-2xl leading-none truncate cursor-pointer"
                :content="project.name + ' - ' + project.description"
                v-tippy="{followCursor : true, arrow: false}">{{project.name}}</div>
                <div class="w-full flex justify-between items-end">
                    <div class="flex flex-col">
                        <div class="flex items-center mb-1 text-sm cursor-pointer" :content="project.location"
                        v-tippy="{followCursor : true, arrow: false}">
                            <span class="mdi mdi-map-marker text-orange mr-2"></span>
                            <span class="text-orange">{{project.city}}</span>
                        </div>
                        <div class="legend-xs leading-none">{{project.project_status}}</div>
                    </div>
                    <div v-if="project.joker || project.joker_value" class="legend-xs leading-none">
                        <div class="flex flex-col">
                            <p v-if="project.joker">{{project.joker}}</p>
                            <p class="mt-1" v-if="project.joker_value">{{project.joker_value}}</p>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="legend-xs leading-none flex items-center text-right flex items-center justify-end">{{project.code}}</div>
                        <div class="legend-xs leading-none flex items-center">
                            <span class="mdi mdi-calendar mr-2 text-lg"></span>{{project.date | moment('DD/MM/YY')}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-6/12 xl:w-7/12 h-full flex ">
            <Ratios :data="ratios" :dashboard="dashboard" />
        </div>
  </div>
</template>

<script>
import Ratios from '@/components/Ratios.vue';
import { state } from '@/store';
export default {
    name: 'ProjectHeader',
    components: {
        Ratios,
    },
    props: ['data','project'],
    computed: {
        user() {
            return state.user;
        },
        ratios() {
            return state.ratios;
        },
        dashboard() {
            return state.dashboard;
        },
    },
}
</script>

<style>

</style>