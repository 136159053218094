<template>
    <div class="module flex-col">
        <div class="w-full flex justify-between items-center">
            <div class="box-title">Producción</div>
            <Legend :colorMain="'#F0961A'" :colorSecondary="'#A279E2'" :legendMain="'Previsión'" :legendSecondary="'Producción'" />
        </div>
        <div class="w-full flex flex-1 h-32">
            <template v-if="loading">
                <div class="w-full h-full flex justify-center items-center">
                    <img class="loader" src="/img/loader.svg" />
                </div>
            </template>
            <template v-else>
                <div class="w-auto">
                    <div class="flex flex-col mr-3">
                        <div class="w-1/2">
                            <div class="text-xxs text-grayText">AC.</div>
                            <div class="text-white font-medium text-xl">{{data.ac | numFormat('0.00')}}%</div>
                            <!-- <div class="text-grayText font-medium">285k€</div> -->
                        </div>
                        <div class="w-1/2">
                            <div class="text-xxs text-grayText mt-2">Desviación</div>
                            <div class="font-medium text-xl" :class="status(data.deviation)">{{data.deviation | numFormat('0.00')}}%</div>
                        </div>
                    </div>
                </div>
                <div v-if="data.evolution" class="w-auto flex-1">
                    <AreaChart :serie1="data.evolution.prevision" :serie2="data.evolution.production" :name1="'Previsión'" :name2="'Producción'" :chartId="'chartId'" :areaColor="'#A279E2'" :lineColor="'#F0961A'" :symbol="'%'"  />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import AreaChart from '@/components/charts/AreaChart.vue';
import Legend from '@/components/charts/Legend.vue';
export default {
    name: 'InvestmentChart',
    props: ['data','loading'],
    components: {
        AreaChart,
        Legend,
    },
    methods: {
        status(status) {
            return {
                'text-good' : status > 0,
                'text-bad' : status <= 0,
            }
        },
    }
}
</script>

<style>

</style>