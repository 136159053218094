<template>
    <div class="box flex-grow h-auto overflow-auto" style="height: 65vh;">
        <template v-if="loading">
            <div class="w-full h-full flex justify-center items-center">
                <img class="loader" src="/img/loader.svg" />
            </div>
        </template>
        <template v-else>
            <div class="flex flex-wrap">
                <div class="w-full flex items-center my-1 pt-2 opacity-50">
                    <div class="xl:pl-2 w-32 text-xs flex flex-col xl:flex-row leading-none py-0 xl:py-1 overflow-hidden text-phase4">Lote</div>
                    <div class="w-1/3 flex legend-xs flex align-center justify-center mx-2">Definición</div>
                    <div class="w-1/3 flex legend-xs flex align-center justify-center mx-2">Contratación</div>
                    <div class="w-1/3 flex legend-xs flex align-center justify-center mx-2">Ejecución</div>
                </div>
                <div v-for="(lote,index) in data.lotes" :key="lote.id" class="w-full flex items-center my-1">
                    <div class="w-32 text-xs flex align-center justify-center leading-none py-0 xl:py-1 overflow-hidden" :class="{'text-phase4' : lote.status == 1, 'text-grayText' : lote.status != 1}">
                        <span class="flex">
                            <span class="px-0 xl:px-2 font-bold text-center xl:mr-0 mr-1" :class="{'text-bad' : lote.status == 2}">{{index + 1}}</span>
                            <span v-if="lote.status == 1" class="mdi mdi-check ml-0"></span>
                        </span>
                        <span class="text-xxs xl:text-sm font-medium truncate w-full cursor-default pr-2 ml-1" :content="lote.name" v-tippy="{followCursor : true, arrow: false}">{{lote.name}}</span>
                    </div>
                    <div class="w-1/3 flex mx-2">
                        <div class="flex-1 relative"
                        :content="'Real: ' + lote.real_definition + ' / Previsto: ' + lote.intended_definition"
                        v-tippy="{followCursor : true, arrow: false}">
                            <div class="relative h-3 xl:h-5 transition-all ease-in-out duration-500 bg-phase1 z-10"  :style="'width:' + lote.real_definition + '%'"></div>
                            <div class="absolute inset-0 h-3 xl:h-5 transition-all ease-in-out duration-500 bg-phaseRed z-0"  :style="'width:' + lote.intended_definition + '%'"></div>
                            <div class="absolute left-0 top-0 flex justify-between flex-1 w-full z-20">
                                <div v-for="bar in divisions" :key="bar" class="w-auto flex-1 h-3 xl:h-5 bg-phase1 bg-opacity-25 border xl:border-2 border-box"></div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3 flex mx-2">
                        <div class="flex-1 relative"
                        :content="'Real: ' + lote.real_hiring + ' / Previsto: ' + lote.planned_hiring"
                        v-tippy="{followCursor : true, arrow: false}">
                            <div class="relative h-3 xl:h-5 transition-all ease-in-out duration-500 bg-phase2 z-10"  :style="'width:' + lote.real_hiring + '%'"></div>
                            <div class="absolute inset-0 h-3 xl:h-5 transition-all ease-in-out duration-500 bg-phaseRed z-0"  :style="'width:' + lote.planned_hiring + '%'"></div>
                            <div class="absolute left-0 top-0 flex justify-between flex-1 w-full z-20">
                                <div v-for="bar in divisions" :key="bar" class="w-auto flex-1 h-3 xl:h-5 bg-phase2 bg-opacity-25 border xl:border-2 border-box"></div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3 flex mx-2">
                    <div class="flex-1 relative"
                        :content="'Real: ' + lote.royal_execution + ' / Previsto: ' + lote.planned_execution"
                        v-tippy="{followCursor : true, arrow: false}">
                            <div class="relative h-3 xl:h-5 transition-all ease-in-out duration-500 bg-phase3 z-10"  :style="'width:' + lote.royal_execution + '%'"></div>
                            <div class="absolute inset-0 h-3 xl:h-5 transition-all ease-in-out duration-500 bg-phaseRed z-0"  :style="'width:' + lote.planned_execution + '%'"></div>
                            <div class="absolute left-0 top-0 flex justify-between flex-1 w-full z-20">
                                <div v-for="bar in divisions" :key="bar" class="w-auto flex-1 h-3 xl:h-5 bg-phase3 bg-opacity-25 border xl:border-2 border-box"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ConstructionLotes',
    props: ['data','loading'],
    data() {
        return {
            finished: true,
            divisions: 10,
            // finished para lotes terminador, aparece el check y se ponen en color azul
            lotes: [{
                    id: 1,
                    status: true,
                },{
                    id: 2,
                    status: true,
                },{
                    id: 3,
                    status: true,
                },{
                    id: 4,
                    status: true,
                },{
                    id: 5,
                    status: true,
                },{
                    id: 6,
                    status: false,
                },{
                    id: 7,
                    status: false,
                },{
                    id: 8,
                    status: false,
                },{
                    id: 9,
                    status: false,
                },{
                    id: 10,
                    status: false,
                }
            ]
        }
    },
    methods: {

    }
}
</script>

<style>

</style>