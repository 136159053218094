import Model from "../Model";

class Auth extends Model {
    buildUrl ({ action }) {
        return [action];
    }

    async login(form) {
        return await this.request({
            method: 'POST',
            url: 'api/users/login',
            data: form
        });
    }

    async getAuthUser() {
        return await this.request({
            method: 'GET',
            url: 'api/users/me'
        });
    }

    async logout() {
        return await this.request({
            action: 'api/users/logout',
            method: 'POST'
        });
    }

    async sendResetLink(form) {
        return await this.request({
            action: 'api/users/send-reset-link',
            method: 'POST',
            data: form
        });
    }

    async passwordResetSave(form) {
        return await this.request({
            action: 'api/users/password-reset-save',
            method: 'POST',
            data: form
        });
    }
};

export default new Auth;