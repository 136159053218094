<template>
    <div class="w-full timeline-formalities">
        <div class="box h-64" style="box-sizing: content-box;">
            <template v-if="loading">
                <div class="w-full h-full flex justify-center items-center">
                    <img class="loader" src="/img/loader.svg" />
                </div>
            </template>
            <template v-else>
                <!-- <TimelineChart v-if="data.formalities" :data="data.formalities" :id="'timeline-chart'" :status="'#FF6565'" /> -->
                <Timeline :data="data.formalities_percent" />
            </template>
        </div>
    </div>
</template>

<script>
import Timeline from '@/components/charts/Timeline.vue';
export default {
    name: 'FormalitiesTimeline',
    components: {
        Timeline,
    },
    props: ['data','loading'],
}
</script>

<style>

</style>