<template>
    <div class="w-11/12 mx-auto h-full flex items-center relative">
        <div class="absolute top-0 left-0 w-full h-full flex items-center">
            <div class="w-full h-1 bg-grayText rounded-sm"></div>
        </div>

        <div class="w-full flex items-center absolute left-0 z-50">
            <div v-for="(item, index) in data" :key="item.id" class="label absolute w-full h-full" :style="'left:' + item.real + '%;'"
                :content="item.name + ' - ' + item.real + '% - ' + item.broad_description"
                v-tippy="{followCursor : true, arrow: false}">
                <div v-show="item.real > 0" class="w-32 h-auto bg-module rounded-md absolute p-2 text-sm border-2 flex justify-between" :class="status(item.status, index)">
                    <div class="absolute top-0 left-0 w-4 h-4 rounded-full flex justify-center items-center bg-module border font-medium z-50 -mt-2 -ml-2" :class="status(item.status)">{{index + 1}}</div>
                    <div class="w-9/12 text-xs text-grayText leading-none">{{item.name}}</div>
                    <div class="w-3/12 text-white">{{item.real}}%</div>
                </div>                
            </div>           
        </div>
        <div class="w-full flex items-center absolute left-0">
            <div v-for="(item, index) in data" :key="item.id" class="w-full h-full">
                <div v-if="item.real > 0" class="w-4 h-4 -mt-2 rounded-full absolute flex justify-center items-center font-medium text-xs" :class="colorStatus(item.status)" :style="'left:' + item.real + '%;'">
                    {{index + 1}}
                </div>            
            </div>         
        </div>


        <div class="w-full h-full flex justify-between items-center z-0 absolute left-0 top-0 mt-4 font-medium text-sm text-grayText">
            <div>0%</div> 
            <div>50%</div> 
            <div>100%</div> 
        </div>

    </div>
</template>

<script>
export default {
    props: ['data'],
    data() {
        return {

        }
    },
    methods: {
        status(status, index) {
            return {
                'border-bad text-bad' : status == 0,
                'border-good text-good' : status == 1,
                '-mt-16' :  index == 3 || index == 7 || index == 11,
                '-mt-32' : index == 1 || index == 5 || index == 9,
                
                'mt-4' : index == 2 || index == 6 || index == 10,
                'mt-20' : index == 0 || index == 4 || index == 8 || index == 12,
            }
        },
        colorStatus(status) {
            return {
                'bg-bad' : status == 0,
                'bg-good' : status == 1,
            }
        },
    }
}
</script>

<style>

.label {
    margin-left: -3.5%;
    cursor: pointer;
}

/* .label:nth-child(2n+1) {
    top: -4rem;
}
.label:nth-child(2n+3) {
    top: -6rem;
}
.label:nth-child(2n+2){
    bottom: -6rem;
}
.label:nth-child(2n+4){
    bottom: -4rem;
} */
</style>