<template>
     <div class="w-full h-full flex items-center justify-center bg-dotted bg-opacity-50">
         <div>
            <div class="flex flex-col items-center">
                <img class="mx-auto w-48" alt="Vue logo" src="@/assets/img/10tlogo.png">
            </div>

            <Request
                model="Auth"
                action="login"
                :form="form"
                @success="onSuccess"
                @error="onError"
                v-slot="{ request, loading }">

                <div
                    @keypress.enter="request"
                    class="mt-6 w-full max-w-xs p-6 bg-module rounded-lg shadow-lg">

                    <label class="block w-full">
                        <p class="text-sm text-gray-600">Email</p>
                        <input type="email" v-model="form.email" class="mt-1 form-input py-1 w-full">
                    </label>

                    <label class="mt-2 block w-full">
                        <p class="text-sm text-gray-600">Contraseña</p>
                        <input type="password" v-model="form.password" class="mt-1 form-input py-1 w-full">
                    </label>

                    <p v-if="errorLogin" class="text-sm text-red-400 text-center mt-4">{{ errorLogin }}</p>

                    <div class="w-full pt-4 pb-0 text-gray-600 text-center text-xs">
                        <button @click="resetPassword" class="focus:outline-none">Restablece tu contraseña aquí</button>
                    </div>

                    <div class="mt-4">
                        <button
                            @click="request"
                            class="w-full px-4 py-2 bg-ownColor font-medium text-indigo-100 rounded-lg"
                            :class="{ 'opacity-50': loading }"
                            :disabled="loading">
                            Login
                        </button>
                    </div>
                </div>
            </Request>
        </div>
    </div>
</template>

<script>
    import { Request } from '@/api/components';
    import { actions } from '@/store';
    import { Auth } from '@/api/models';

    export default {
        name: 'Login',
        components: {
            Request
        },
        data() {
            return {
                form: {
                    email: '',
                    password: ''
                },
                errorLogin: ''
            };
        },
        methods: {
            async onSuccess(response) {
                await actions.setToken(response.token);
                let user = await Auth.getAuthUser();
                await actions.setUser(user);
                actions.setDashboard('investment');
                switch(user.data.role_id) {
                    case 1:
                        window.location.href = process.env.VUE_APP_BACK_URL_SUPER_ADMIN;
                        break;
                    case 2:
                        window.location.href = process.env.VUE_APP_BACK_URL_PROMOTER_ADMINISTRATOR;
                        break;
                    case 3:
                        window.location.href = process.env.VUE_APP_BACK_URL_PROJECT_DIRECTOR;
                        break;
                    default:
                        this.$router.push({ name: 'Project' });
                }

            },
            onError(error) {
                this.errorLogin = 'Las credenciales no son correctas';
            },
            resetPassword() {
                this.$router.push({ name: 'SendResetLink' });
            },
        }
    }
</script>

