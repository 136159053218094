export default (authGuard, guestGuard) => [
    ...guestGuard([
        {
            path: '/login',
            name: 'login',
            component: () => import('@/pages/login'),
        },
        {
            path: '/send-reset-link',
            name: 'SendResetLink',
            component: () => import('@/pages/passwords/SendResetLink')
        },
        {
            path: '/password-reset/:token',
            name: 'PasswordReset',
            component: () => import('@/pages/passwords/PasswordReset')
        }
    ]),

    ...authGuard([
        {
            path: '/',
            name: 'home',
            //meta: { layout: 'app' },
            component: () => import('@/pages/home'),
            redirect: { name: 'Project' },
            children: [
                {
                    path: '/formalities',
                    name: 'Formalities',
                    props: true,
                    component: () => import('@/components/Formalities.vue'),
                },
                {
                    path: '/investment',
                    name: 'Investment',
                    props: true,
                    component: () => import('@/components/Investment.vue'),
                },
                {
                    path: '/construction',
                    name: 'Construction',
                    props: true,
                    component: () => import('@/components/Construction.vue'),
                },
            ]
        },{
            path: '/project',
            name: 'Project',
            component: () => import('@/pages/project'),
        },
    ]),

    {
        path: '*',
        name: '404',
        redirect: { name: 'home' }
    }
];