<template>
    <div class="w-full flex flex-col my-4 relative overflow-auto" style="max-height: 28rem;">
        <template v-if="loading">
            <div class="w-full h-64 flex justify-center items-center">
                <img class="loader" src="/img/loader.svg" />
            </div>
        </template>
        <template v-else>
            <div class="absolute top-0 left-0 z-0 w-full">
                <div v-for="event in data.formalities" :key="event.id" class="plotband bg-box border-box border-2 w-full z-0 mb-1 rounded-l-md " style="height: 72px;"></div>
            </div>
            <div class="flex w-full relative z-10">
                <div class="flex-none mb-3 ">
                    <div @click="sentFormality(event.id)" v-for="event in data.formalities" :key="event.id" class="cursor-pointer border-2 w-48 mb-1 rounded-md flex flex-col justify-between p-2" style="height: 72px;" :class="rangeStatus(event.status, event.id)">
                        <div class="text-white text-sm truncate">{{event.name}}</div>
                        <div class="w-full flex justify-between items-center text-xs">
                            <div class="w-1/2 flex items-center">
                                <span class="legend-xs mr-2">Real</span><span class="text-white">{{event.real | numFormat('0.0')}}%</span>
                            </div>
                            <div class="w-1/2 flex items-center justify-end">
                                <span class="legend-xs mr-2">Teórico</span><span class="text-grayText">{{event.theoretical | numFormat('0.0')}}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="data.formalities" class=" w-full overflow-hidden">
                    <RangeChart :data="data.formalities" :id="'range-chart'" class="h-full" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import FormalitiesRow from '@/components/formalities/FormalitiesRow.vue';
    import RangeChart from '@/components/charts/RangeChart.vue';
    export default {
        name: 'FormalitiesGantt',
        components: {
            FormalitiesRow,
            RangeChart,
        },
        props: ['data','loading'],
        data() {
            return {
                currentFormality: '',
            }
        },
        methods: {
            rangeStatus(status, id) {
                return {
                    'border-bad bg-bad bg-opacity-25' : status == 0 && id != this.currentFormality,
                    'border-area bg-area' : status == 1 && id != this.currentFormality,
                    'border-main bg-area' : id == this.currentFormality && status == 1,
                    'border-main bg-bad bg-opacity-25' : id == this.currentFormality && status == 0,
                }
            },
            sentFormality(formality) {
                this.currentFormality = formality;
                this.$emit('sentFormality',formality);
            }
        },
    }
</script>

<style>

</style>