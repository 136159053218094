<template>
    <div class="w-screen h-screen flex">
        <div class="w-20 extra:w-56 xl:w-64 p-2 relative">
            <ul class="fixed top-0 w-20 extra:w-56 xl:w-64 my-2">
                <div @click="formalities">Boton</div>
                <li>
                    <router-link
                        :to="{ path: '/formalities' }"
                        v-slot="{ href, route, navigate, isActive, isExactActive }">
                        <NavInvestment @click="navigate" />
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{ name: 'Formalities' }"
                        v-slot="{ href, route, navigate, isActive, isExactActive }">
                        <NavFormalities @click="navigate" />
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{ path: '/formalities' }"
                        v-slot="{ href, route, navigate, isActive, isExactActive }">
                        <NavConstruction @click="navigate" />
                    </router-link>
                </li>
                <a href="#" class="flex items-center my-2 text-color4 cursor-pointer" @click="logout">
                    <i class="mdi mdi-logout mr-6 text-lg"></i>
                    <span class="text-sm">Logout</span>
                </a>
            </ul>
        </div>

        <div class="flex-1 min-w-0 bg-gray-200">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import { Request } from '@/api/components';
    import { actions } from '@/store';
    import NavConstruction from '@/components/navigation/NavConstruction.vue';
    // import NavIncomes from '@/components/navigation/NavIncomes.vue';
    // import NavMaintenance from '@/components/navigation/NavMaintenance.vue';
    import NavInvestment from '@/components/navigation/NavInvestment.vue';
    import NavFormalities from '@/components/navigation/NavFormalities.vue';
    import { Auth } from '@/api/models';

    export default {
        name: 'AppLayout',
        components: {
            Request,
            NavConstruction,
            // NavIncomes,
            // NavMaintenance,
            NavInvestment,
            NavFormalities,
        },
        computed: {
        projectId() {
            return this.$route.query.id;
        },
        excelId() {
            return this.$route.query.excel;
        },
        query() {
            return {
                projectId: this.projectId,
                excelId: this.excelId
            }
        },
        },
        methods: {
            async logout(){
                await Auth.logout();
                await actions.resetAuth();
                this.$router.push({ name: 'login' });
            },
            formalities(){
                this.$router.push({ name: 'Formalities', params: { id: 4, excel: 4} });
            }
        }
    }
</script>