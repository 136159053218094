<template>
        <div class="w-full h-full flex flex-col items-center justify-center bg-dotted bg-opacity-50">
            <div class="flex flex-col items-center">
                <a href="/">
                    <img class="mx-auto w-48" alt="Vue logo" src="@/assets/img/10tlogo.png">
                </a>
            </div>
            <div class="mt-6 w-full max-w-xs p-6 bg-module rounded-lg shadow-lg">
                <div class="w-full">
                    <label class="block text-center">
                        <p class="text-white pb-2">Podemos enviarte un enlace para que restablezcas tu contraseña.</p>
                        <p class="text-white pb-2">Indica aquí el correo de tu cuenta</p>
                        <input v-model="form.email" class="form-input mt-2 block w-full text-center" placeholder="E-mail" autofocus>
                    </label>
                </div>
                <p v-if="errorLogin" class="text-sm text-red-400 text-center pt-4 first-letter">{{ errorLogin }}</p>
                <p v-if="success" class="text-sm text-green-400 text-center pt-4">{{ success }}</p>
                <div class="w-full pt-4 text-center">
                    <a v-if="back" class="w-full px-4 py-2 bg-ownColor font-medium text-indigo-100 rounded-lg text-center flex justify-center align-center" href="/">Volver</a>
                    <button
                        v-if="!back"
                        @click="sendEmail"
                        class="w-full px-4 py-2 bg-ownColor font-medium text-indigo-100 rounded-lg"
                        :class="{ 'opacity-50': loading }"
                        :disabled="loading">
                        Enviar
                    </button>
                    <Request
                        model="Auth"
                        ref="sendResetLinkRef"
                        :form="form"
                        action="sendResetLink"
                        @success="onSuccess"
                        @error="onError"
                        v-slot="{ request, loading }"
                    />
                </div>
            </div>
        </div>
</template>

<script>
import { Request } from '@/api/components';

export default {
    name: "SendResetLink",
    components: {
        Request
    },
    data() {
        return {
            form:{
                email: null,
            },
            errorLogin: '',
            success: '',
            back: '',
            loading: false
        };
    },
    methods: {
        onError(errors) {
            this.loading = false;
            this.success = '';
            this.errorLogin = errors.email[0];
        },
        async onSuccess(response) {
            this.back = true;
            this.errorLogin = '';
            this.success = 'Email enviado para restablecer contraseña';
        },
        sendEmail() {
            this.loading = true;
            this.$refs.sendResetLinkRef.request();
        },
    }
}
</script>

<style scoped>
.first-letter:first-letter {
    text-transform:uppercase;
}
</style>