var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-screen h-screen flex"},[_c('div',{staticClass:"w-20 extra:w-56 xl:w-64 p-2 relative"},[_c('ul',{staticClass:"fixed top-0 w-20 extra:w-56 xl:w-64 my-2"},[_c('div',{on:{"click":_vm.formalities}},[_vm._v("Boton")]),_c('li',[_c('router-link',{attrs:{"to":{ path: '/formalities' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('NavInvestment',{on:{"click":navigate}})]}}])})],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'Formalities' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('NavFormalities',{on:{"click":navigate}})]}}])})],1),_c('li',[_c('router-link',{attrs:{"to":{ path: '/formalities' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('NavConstruction',{on:{"click":navigate}})]}}])})],1),_c('a',{staticClass:"flex items-center my-2 text-color4 cursor-pointer",attrs:{"href":"#"},on:{"click":_vm.logout}},[_c('i',{staticClass:"mdi mdi-logout mr-6 text-lg"}),_c('span',{staticClass:"text-sm"},[_vm._v("Logout")])])])]),_c('div',{staticClass:"flex-1 min-w-0 bg-gray-200"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }