var _ = require('lodash');

import Vue from 'vue';
import App from './App.vue';
import router from './router';


import './assets/css/main.css';
import '@mdi/font/css/materialdesignicons.min.css';

// HIGHCHARTS
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)
import More from 'highcharts/highcharts-more'
More(Highcharts)
import solidGauge from 'highcharts/modules/solid-gauge'
import xrange from 'highcharts/modules/xrange'
import timeline from 'highcharts/modules/timeline'
solidGauge(Highcharts)
xrange(Highcharts)
timeline(Highcharts)


// TIPPY
import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

// NUMERAL
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
Vue.filter('numFormat', numFormat(numeral));
numeral.register('locale', 'es', {
    delimiters: {
      thousands: '.',
      decimal: ','
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
    },
    ordinal : function (number) {
      return number === 1 ? 'er' : 'ème';
    },
    currency: {
      symbol: '€'
    }
  });
numeral.locale('es');

// MOMENT
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
require('moment/locale/es')
Vue.use(VueMoment, {
    moment,
})


// PORTAL
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

Vue.config.productionTip = false;


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')



