<template>
    <div class="w-1/2 flex justify-end items-center">
        <div v-if="legendSecondary" class="ml-8 flex items-center">
            <div class="w-6 h-1 mr-2" :style="{'background-color': colorSecondary}"></div>
            <div class="legend-xs">{{legendSecondary}}</div>
        </div>
        <div v-if="legendMain" class="ml-8 flex items-center">
            <div class="w-6 h-1 mr-2" :style="{'background-color': colorMain}"></div>
            <div class="legend-xs">{{legendMain}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['colorMain','colorSecondary','legendMain','legendSecondary'],
}
</script>

<style>

</style>