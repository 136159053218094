<template>
    <div class="module flex-col justify-between h-full extra:h-36 cursor-pointer w-full border-2 transition" :class="{'border-main opacity-100' : dashboard == 'formalities', 'border-module opacity-50' : dashboard != 'formalities'}">
        <div class="w-full flex justify-center extra:justify-start items-center">
            <!-- <div class="hidden extra:flex w-3 h-3 bg-good rounded-full mr-3"></div> -->
            <div class="hidden extra:flex font-medium text-grayText">Trámites</div>
            <div class="flex extra:hidden mdi mdi-note text-grayText text-2xl"></div>
        </div>
        <div class="w-full flex flex-col items-center py-1 leading-none">
            <div class="text-white font-medium text-lg extra:text-2xl leading-none">{{data.total}}</div>
            <div class="legend-xs text-center py-2">Trámites en curso</div>
        </div>
        <div class="w-full flex flex-row justify-between items-center py-2 bg-box rounded-md px-2">
            <div class="legend-xs">Estado</div>
            <div class="text-good text-xs extra:text-lg leading-none"><span class="mdi mdi-check"></span> {{data.total_green}}</div>
            <div class="text-bad text-xs extra:text-lg leading-none">{{data.total_red}}</div>
        </div>
    </div>
</template>

<script>
import { state } from '@/store';
export default {
    name: 'NavFormalities',
    props: ['data'],
    computed: {
        dashboard() {
            return state.dashboard;
        },
    },
}
</script>

<style>

</style>