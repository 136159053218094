<template>
    <div class="w-full h-full flex flex-col">
        <All
            model="ProcedureGeneralProcedures"
            :key="'ProcedureGeneral'"
            :immediate="true"
            :sensitive="true"
            :query="query"
            :data.sync="procedure_general"
            v-slot="{ loading }">
        </All>

        <All
            model="Procedure"
            :key="'Procedure'"
            :immediate="true"
            :sensitive="true"
            :query="query"
            :data.sync="procedure"
            v-slot="{ loading }">
            <div>
                <div class="module flex-col">
                    <FormalitiesTimeline :data="procedure" :loading="loading" />
                    <FormalitiesGantt :data="procedure" @sentFormality="sentFormality" :loading="loading" />            
                </div>
                <FormalitiesChart v-if="procedure.formalities" :key="formality" :data="procedure.formalities[formality]" :loading="loading" />
            </div>
        </All>


        
    </div>
</template>

<script>
import FormalitiesTimeline from '@/components/formalities/FormalitiesTimeline.vue';
import FormalitiesGantt from '@/components/formalities/FormalitiesGantt.vue';
import FormalitiesChart from '@/components/formalities/FormalitiesChart.vue';
import { All } from '@/api/components';
import { actions } from '@/store';

export default {
    name: 'Formalities',
    components: {
        FormalitiesTimeline,
        FormalitiesGantt,
        FormalitiesChart,
        All,
    },
    data() {
        return {
            procedure_general: {},
            procedure: {},
            formality: 0,
        }
    },
    computed: {
        projectId() {
            return this.$route.query.id;
        },
        excelId() {
            return this.$route.query.excel;
        },
        query() {
            return {
                projectId: this.projectId,
                excelId: this.excelId
            }
        },
    },
    methods: {
        sentFormality(formality) {
            this.formality = formality;
        }
    },
    watch: {
        procedure_general(procedure_general) {
            actions.setRatios(procedure_general);
        },
        procedure(procedure) {
            actions.setFormalities(procedure);
        }
    },
}
</script>

<style>

</style>