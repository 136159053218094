<template>
    <div class="relative chart-wrap">
        <highcharts :options="options" :ref="id" :id="id"></highcharts>    
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        name: 'RangeChart',
        props: ['id','data'],
        computed: {
            events() {
                let events = [];
                let i = 0
                this.data.forEach((event)=>{
                    let status = (event.status == 1) ? '#00EBFF' : '#FF6565';
                    events.push({
                        x: event.from_date,
                        x2: event.to_date,
                        y: i,
                        partialFill: event.real/100,
                        name: event.name,
                        description: event.brief_description,
                        broad: event.broad_description,
                        color: status,
                        // dataLabels: {
                        //     format: '<div style="border-radius: 5px; color: white; border: none; font-size: 0.7rem;width: 200px;display: flex;justify-content: flex-end;align-items: center;">'+ event.x2 +'</div>'
                        // },
                    })
                    i++;
                })
                return events;
            },
            categories() {
                let categories = [];
                let i = 0
                this.data.forEach((event)=>{
                    categories.push(i);
                    i++;
                })
                return categories;
            },
            options() {
                return  {
                    chart: {
                        type: 'xrange',
                        plotBackgroundColor: null,
                        plotBorderWidth: 0,
                        plotShadow: false,
                        borderWidth:0,
                        backgroundColor: 'rgba(255,255,255,.0)',
                        margin: [0,0,20,10],
                        spacingTop: 0,
                        spacingBottom: 20,
                        spacingRight: 20,
                        spacingLeft: 20,
                    },
                    title: {
                        enabled: false,
                        text: '',
                        //                    align: 'center',
                        //                    verticalAlign: 'middle',
                        //                    y: 40
                    },
                    xAxis: {
                        type: 'datetime',
                        plotLines: [{
                            color: 'rgba(0, 235, 255, 0.37)',
                            width: 2,
                            dashStyle: 'longdash',
                            value: moment().format('x'),
                            zIndex: 99,
                        }],
                        visible: true,
                        lineColor: 'transparent',
                        lineWidth: 0,
                        tickColor: 'transparent',
                        labels: {
                            style: {
                                color:'#79849A',
                            }
                        },
                    },
                    yAxis: {
                        title: {
                            text: ''
                        },
                        labels: {
                            style: {
                                color:'#191E2A',
                                fontSize: '15px',
                            }
                        },
                        categories: this.categories,
                        reversed: true,
                        gridLineColor: 'transparent',

                    },
                    legend: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    tooltip: {
                        //                                            positioner: function () {
                        //                                                 return { x: 200, y: 20 };
                        //                                            },
                        style: {
                            color: '#79849A',
                        },
                        backgroundColor: '#11121A',
                        zIndex: 99999999,
                        borderRadius: 20,
                        useHTML: true,
                        // pointFormat: '{point.name}:<b>{point.broad}</b>',
                        formatter: function() {
                            return '<b>Previsto: </b>' + moment(this.point.x2).format('DD/MM/YY')+' - '+this.point.name
                        }
                    },
                    //  colors: [this.color],
                    pane: {
                        //  backgroundColor: '#000',
                    },
                    plotOptions: {
                        xrange: {                        
                            borderWidth: 0,
                            borderColor: '#191E2A',
                            grouping: false,
                            pointPadding: 0,
                            groupPadding: 0,
                            dataLabels: {                            
                                enabled: true,
                                style: {
                                    color: '#000',
                                    textOutline: 0,
                                },
                                useHTML: true,
                                borderRadius: 10,
                                borderWidth: 2,
                                backgroundColor: 'transparent',
                            },
                        },
                        series: {
                            animation: {
                                duration: 2500
                            },
                            linecap: 'round',
                        }
                    },
                    series: [{
                        name: 'serie',
                        borderColor: 'transparent',
                        pointWidth: 25,
                        data: this.events,
                        dataLabels: [{
                            zIndex: 99,
                            align: 'left',
                            formatter: function() {
                                return moment(this.point.x).format('DD/MM/YY')
                            }
                        }, {
                            zIndex: 99,
                            align: 'right',
                            formatter: function() {
                                return moment(this.point.x2).format('DD/MM/YY')
                            }
                        }],
                        zoneAxis: 'y',
                    }]
                }
            }
        },
    };
</script>