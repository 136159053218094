<template>
    <div class="box h-auto xl:h-32 flex-1 flex flex-col justify-around">
        <template v-if="loading">
            <div class="w-full h-full flex justify-center items-center">
                <img class="loader" src="/img/loader.svg" />
            </div>
        </template>
        <template v-else>
            <div class="flex flex-wrap flex-col xl:flex-row items-center h-full">
                <div class="w-full xl:w-1/2 flex flex-col flex-grow items-center my-1">
                    <div class="text-xxs text-grayText">% E.Negativos</div>
                    <div class="flex items-center">
                        <div class="mdi mr-2 mdi-security text-grayText"></div>
                        <div class="text-main font-medium text-lg xl:text-xl leading-none">{{data.security | numFormat('0.0')}}%</div>
                    </div>
                </div>
                <div class="w-full xl:w-1/2 flex flex-col flex-grow items-center my-1">
                    <div class="text-xxs text-grayText">C.Calidad</div>
                    <div class="flex items-center">
                        <div class="mdi mr-2 mdi-shield-check text-grayText"></div>
                        <div class="text-main font-medium text-lg xl:text-xl leading-none">{{data.quality | numFormat('0.0')}}%</div>
                    </div>
                </div>
                <div class="w-full xl:w-1/2 flex flex-col flex-grow items-center my-1">
                    <div class="text-xxs text-grayText">Í.Riesgo</div>
                    <div class="flex items-center">
                        <div class="mdi mr-2 mdi-bottle-tonic-plus text-grayText"></div>
                        <div class="text-main font-medium text-lg xl:text-xl leading-none">{{data.risk | numFormat('0.0')}}%</div>
                    </div>
                </div>
                <div class="w-full xl:w-1/2 flex flex-col flex-grow items-center my-1">
                    <div class="text-xxs text-grayText">Trabajadores</div>
                    <div class="flex items-center">
                        <!-- <div class="mdi mdi-earth text-main text-2xl"></div> -->
                        <div class="mdi mr-2 mdi-account text-grayText"></div>
                        <div class="text-main font-medium text-lg xl:text-xl leading-none">{{data.workers | numFormat('0,0')}}</div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ConstructionKpi',
    props: ['data','loading'],
    data() {
        return {
            
        }
    },
    methods: {
        actionStatus(status) {
            return {
                'border-bad text-bad bg-bad py-1' : status == 'bad',
                'border-regular text-regular bg-regular' : status == 'regular',
                'border-good text-good bg-good' : status == 'good',
            }
        }
    }
}
</script>

<style>

</style>