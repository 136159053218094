var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-full flex flex-col"},[_c('All',{key:'InvestmentGeneral',attrs:{"model":"InvestmentControlGeneralInvestment","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.investment_general},on:{"update:data":function($event){_vm.investment_general=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return undefined}}])}),_c('div',{staticClass:"module flex-grow"},[_c('div',{staticClass:"w-full flex h-full"},[_c('div',{staticClass:"w-8/12 px-2 flex flex-col h-full"},[_c('All',{key:'InvestmentPie',attrs:{"model":"InvestmentControlInvestmentBilled","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.investment_pie},on:{"update:data":function($event){_vm.investment_pie=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('InvestmentPie',{attrs:{"data":_vm.investment_pie,"loading":loading}})]}}])}),_c('All',{key:'InvestmentKpi',attrs:{"model":"InvestmentControlInvestmentApproveds","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.investment_kpi},on:{"update:data":function($event){_vm.investment_kpi=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('InvestmentKpi',{attrs:{"data":_vm.investment_kpi,"loading":loading}})]}}])})],1),_c('div',{staticClass:"w-4/12 px-2 flex flex-col"},[_c('All',{key:'InvestmentTimeline',attrs:{"model":"InvestmentControlInvestmentTimeline","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.investment_timeline},on:{"update:data":function($event){_vm.investment_timeline=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('InvestmentTimeline',{attrs:{"data":_vm.investment_timeline,"loading":loading}})]}}])}),_c('All',{key:'InvestmentAlerts',attrs:{"model":"InvestmentControlInvestmentAlerts","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.investment_alerts},on:{"update:data":function($event){_vm.investment_alerts=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('InvestmentAlerts',{attrs:{"data":_vm.investment_alerts,"loading":loading}})]}}])})],1)])]),_c('All',{key:'InvestmentChart',attrs:{"model":"InvestmentControlEvolutionaries","immediate":true,"sensitive":true,"query":_vm.query,"data":_vm.investment_chart},on:{"update:data":function($event){_vm.investment_chart=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('InvestmentChart',{attrs:{"data":_vm.investment_chart,"loading":loading}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }