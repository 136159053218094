<template>
    <div class="w-full h-full flex items-center justify-center bg-dotted bg-opacity-50">
        <div>
            <div class="flex flex-col items-center">
                <img class="mx-auto w-48" alt="Vue logo" src="@/assets/img/10tlogo.png">
                <!-- <div class="text-xl text-gray-800 font-bold my-2">Welcome</div> -->
            </div>

            <All
                model="UserProjects"
                :immediate="true"
                :sensitive="true"
                :data.sync="projects"
                v-slot="{ loading }">


                <div class="mt-6 w-full max-w-sm p-6 bg-module rounded-lg shadow-lg">
                    <div class="text-xl text-center text-gray-300 my-5 leading-none"> <span class="font-bold">¡Hola!</span> Accede a un proyecto para empezar</div>
                    <label class="block mt-4">
                        <span class="text-gray-700">Selecciona un <span class="text-ownColor font-medium">proyecto</span></span>
                        <select class="form-select mt-1 block w-full cursor-pointer" v-model="selectedProject" @change="onChange($event)">
                            <option :value="null" disabled selected>Elegir proyecto</option>
                            <option v-for="(project,index) in projects" :key="project.id" :value="project.id" v-bind:selected="index === 0 ? 'selected' : false">{{project.name}}</option>
                        </select>
                    </label>
                    <!-- <label class="block mt-4">
                        <span class="text-gray-700">Selecciona un <span class="text-ownColor font-medium">excel</span></span>
                        <select class="form-select mt-1 block w-full">
                            <option v-for="excel in projects.excels" :key="excel.id">{{excel.id}}</option>
                        </select>
                    </label> -->
                    <div class="mt-4">
                        <button
                            @click="access"
                            class="w-full px-4 py-2 font-medium rounded-lg"
                            :class="{ 'opacity-50': loading ,'bg-gray-500 text-gray-400' : !selectedProject,'bg-ownColor text-indigo-100' : selectedProject}"
                            :disabled="!selectedProject">
                            Acceder
                        </button>
                    </div>

                </div>

            </All>
                <a href="#" class="w-full flex justify-center items-center my-2 cursor-pointer" @click="logout">
                    <i class="mdi mdi-logout mr-2 text-md text-ownColor"></i>
                    <span class="text-sm text-grayText">Logout</span>
                </a>
        </div>
    </div>

</template>

<script>

import { All } from '@/api/components';
import { Auth } from '@/api/models';
import { actions, state } from '@/store';
export default {
    name: 'Project',
    components: {
        All,
    },
    data() {
        return {
            selectedProject: null,
            projects: [],
            project: '',
            excel: ""
        }
    },
    computed: {
        user() {
            return state.user;
        },
    },
    watch :{
        // projects() {
        //     this.selectedProject = this.projects[0]
        // }
    },
    methods: {
        onChange(event) {
            this.project = event.target.value;
            this.excel = event.target.value;
        },
        access(){
            this.$router.push({ name: 'Investment', query: { id: this.project} });
            actions.setDashboard('investment');
        },
        async logout(){
            await Auth.logout();
            await actions.resetAuth();
            this.$router.push({ name: 'login' });
        },
    },
    mounted() {
        if(this.projects) {
            // console.log(this.projects,' project de 0');
        }

    }
}
</script>

<style>

</style>