<template>
    <div class="relative chart-wrap">
        <highcharts class="chart w-full h-full" :options="options" :ref="chartId" :id="chartId"></highcharts>
    </div>
</template>

<script>
export default {
    name: 'AreaSimpleChart',
    props: ['chartId','serie1','serie2','name1','name2','areaColor','lineColor'],
    data: function(){
        return {
        }
    },
    computed: {
        options() {
            return  {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    backgroundColor:'rgba(255,255,255,0)',
                    spacingBottom: 20,
                    marginBottom: 20,
                    spacingTop: 100,
                    marginTop: 10,
                    animation: {
                        duration: 2000,
                        delay: 3000
                    },
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                credits:{
                    enabled: false
                },
                xAxis: [
                    {
                        type: 'datetime',
                        dateTimeLabelFormats: {
                            month: '%b %Y'
                        },
                        lineColor: 'rgba(255,255,255,0)',
                        title: {
                            text: null
                        },
                        labels: {
                            enabled: true,
                            style:{
                                color:'#79849A',
                                fontSize: '9px',
                            }
                        },
                        tickPixelInterval: 40,
                        tickWidth: 0,
                        gridLineWidth: 0,
                        gridLineColor: '#fff',
                    },{
                        // type: 'datetime',
                        // dateTimeLabelFormats: {
                        //     month: '%b'
                        // },
                        lineColor: 'rgba(255,255,255,0)',
                        title: {
                            text: null
                        },
                        labels: {
                            enabled: false,
                            style:{
                                color:'#79849A',
                                fontSize: '9px',
                            }
                        },
                        tickPixelInterval: 50,
                        tickWidth: 0,
                        gridLineWidth: 0,
                        gridLineColor: '#fff',
                        opposite: true,
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    },
                ],
                exporting:{
                    enabled:false
                },
                yAxis: {
                    title: {
                        enabled: false
                    },
                    gridLineColor: 'transparent',
                    labels: {
                        enabled: true,
                        style:{
                            color:'#79849A',
                            fontSize: '8px',
                        },
                    },
                    lineWidth: 0,
                    endOnTick: false,
                },
                tooltip: {
                   style: {
                        color: '#79849A',
                    },
                    borderRadius: 20,
                    xDateFormat: '%b %Y',
                },
                plotOptions: {
                    areaspline:{
                        fillOpacity: .5,
                        lineWidth: 2,
                        marker:{
                            enabled:false
                        },
                        series: {
                            animation: {
                                duration: 4000
                            },
                            shadow: true,
                            pointIntervalUnit: 'month',
                        },
                    },
                    spline:{
                        fillOpacity: .5,
                        lineWidth: 3,
                        marker:{
                            enabled:false
                        },
                        series: {
                            animation: {
                                duration: 4000
                            },
                            shadow: true,
                            pointIntervalUnit: 'month',
                        },
                    },
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                data: {
                    enablePolling: true,
                    dataRefreshRate: 1
                },
                series: [
                    {
                        type: 'areaspline',
                        // xAxis: 1,
                        name: this.name1,
                        data: this.serie1,
                        color: 'transparent',
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, this.areaColor + 44],
                                [1, '#202837'],
                                [2, 'transparent'],
                                [3, 'transparent'],
                            ]
                        },
                    },
                    {
                        type: 'spline',
                        name: this.name2,
                        data: this.serie1,
                        color: {
                            linearGradient: [200, 0, 0, 0],
                            stops: [
                                [0, this.lineColor],
                                [1, this.lineColor],
                                [2, 'transparent'],
                                [3, 'transparent'],
                            ]
                        },
                        shadow: {
                            offsetX: 0,
                            offsetY: 3,
                            opacity: .035,
                            width: 10,
                            color: '#69301B',
                        },
                    }
                ]

            }
        }
    },
};
</script>