import Model from "../Model";

class User extends Model {
    buildUrl ({ param }) {
        return ['api/users', param];
    }

    // async byId(form) {
    //     return await this.request({
    //         method: 'POST',
    //         url: 'api/users',
    //         data: form
    //     });
    // }

    async getAuthUser() {
        return await this.request({
            method: 'GET',
            url: 'api/users/me'
        });
    }
    // return await this.request({
        // method: 'GET',
        // url: 'api/users/me'
    // });

    // async store(form) {
    //     return await this.request({
    //         method: 'POST',
    //         url: 'api/users/store',
    //         data: form
    //     });
    // }

    // async update(form) {
    //     return await this.request({
    //         method: 'POST',
    //         url: 'api/users/update',
    //         data: form
    //     });
    // }

    // async destroy(form) {
    //     return await this.request({
    //         method: 'POST',
    //         url: 'api/users/destroy',
    //         data: form
    //     });
    // }
}

export default new User;