<template>
    <All model="PasswordReset" ref="passwordResetRef" :immediate="true" v-slot="{data:passwordReset}"
            :query="passwordResetFilter" @loading:change="(event) => loading = event">
        <div class="w-full h-full flex flex-col items-center justify-center bg-dotted bg-opacity-50">
            <div class="flex flex-col items-center">
                <a href="/">
                    <img class="mx-auto w-48" alt="Vue logo" src="@/assets/img/10tlogo.png">
                </a>
            </div>
            <div v-if="!passwordReset.user" class="mt-6 w-full max-w-xs p-6 pt-3 bg-module rounded-lg shadow-lg text-white flex flex-col justify-center align-center">
                <p class="text-sm text-gray-400 text-center pt-4">{{ errorToken }}</p>
                <a class="mt-5 w-full px-4 py-2 bg-ownColor font-medium text-indigo-100 rounded-lg text-center" href="/">Volver</a>
            </div>
            <div v-if="passwordReset.user" class="mt-6 w-full max-w-xs p-6 bg-module rounded-lg shadow-lg text-white">
                <div class="w-full p-2 flex flex-col justify-start items-center">
                    <p class="text-lg ">Hola <span class="font-semibold">{{ passwordReset.user.name }}</span>!</p>
                    <p class="text-center pt-2">Establece aquí tu nueva contraseña.</p>
                </div>
                <div class="w-full">
                    <label class="block">
                        <span class="text-sm text-gray-600">Contraseña</span>
                        <input type="password" v-model="form.password" class="form-input mt-1 block w-full text-center text-black" placeholder="Contraseña" autofocus ref="passwordRef">
                    </label>
                </div>
                <div class="w-full pt-3">
                    <label class="block">
                        <span class="text-sm text-gray-600">Confirme contraseña</span>
                        <input type="password" v-model="form.password_confirmation" class="form-input mt-1 block w-full text-center text-black" placeholder="Confirme contraseña">
                    </label>
                </div>
                <p v-if="errorLogin" class="text-sm text-red-400 text-center pt-4">{{ errorLogin }}</p>
                <p v-if="success" class="text-sm text-green-400 text-center pt-4">{{ success }}</p>
                <div class="w-full pt-6">
                    <Request
                        model="Auth"
                        ref="passwordResetSaveRef"
                        :form="form"
                        action="passwordResetSave"
                        @success="onSuccess"
                        @error="onError"
                        v-slot="{ request, loading }"
                    />
                    <button
                        @click="passwordResetSave"
                        class="w-full px-4 py-2 bg-ownColor font-medium text-indigo-100 rounded-lg"
                        :class="{ 'opacity-50': loading }"
                    >{{ nameButton }}</button>
                </div>
            </div>
        </div>
    </All>
</template>

<script>
import {All} from "@/api/components";
import {Request} from "@/api/components";

export default {
    name: "PasswordReset",
    components: {
        All, Request
    },
    data() {
        return {
            passwordResetFilter: {
                token: this.$route.params.token,
            },
            form: {
                token: this.$route.params.token,
                password: null,
                password_confirmation: null,
            },
            errorLogin: '',
            success: '',
            errorToken: 'El token de sesión a expirado o ha sido utilizado',
            nameButton: 'Establecer nueva contraseña',
            loading: false
        };
    },
    methods: {
        onError(errors) {
            this.errorLogin = errors.password[0];
            this.success = '';
            this.loading = false;
        },
        async onSuccess(response) {
            this.errorLogin = '';
            this.success = response.success;
            this.nameButton = 'Redirigiendo...';
            setTimeout(function () { this.$router.push({ name: 'login' }) }.bind(this), 2000);
        },
        passwordResetSave() {
            this.loading = true;
            this.$refs.passwordResetSaveRef.request();
        }
    }
}
</script>

<style scoped>
</style>