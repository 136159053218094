<template>
    <div class="w-full flex -mx-2 h-full">
        <div class="w-1/2 px-2">
            <div class="box mb-2 h-full mb-4">                
                <div class="box-title flex items-center"><div class="w-4 h-4 rounded-full mr-3" :class="investmentStatus(data.deviation)"></div>Inversión</div>
                <div class="w-full relative h-92 xl:h-94">
                    <template v-if="loading">
                        <div class="w-full h-full flex justify-center items-center">
                            <img class="loader" src="/img/loader.svg" />
                        </div>
                    </template>
                    <template v-else>
                        <div class="absolute inset-0 flex flex-col justify-center items-center">
                            <span class="legend-xs">Acumulado</span>
                            <div class="text-white font-medium text-2xl xl:text-4xl leading-none">{{data.acumulado_percent | numFormat('0.0')}}<span class="symbol">%</span></div>
                            <span class="font-medium text-sm xl:text-lg leading-none text-white border-b-2 border-area pb-1 mb-1">{{data.acumulado | numFormat('0,0')}}€</span>

                            <span class="legend-xs">Desviación vs previsto</span>
                            <div class="flex items-center leading-none" :class="status(data.deviation_percent)">
                                <span class="font-medium text-sm xl:text-lg leading-none">{{data.deviation_percent | numFormat('0.0')}}%</span>
                            </div>
                            <span class="font-medium text-sm xl:text-lg leading-none" :class="status(data.deviation)">{{data.deviation | numFormat('0,0')}}€</span>
                        </div>
                        <GaugeChart :data="data.acumulado_percent" :pieOptions="pieOptions" :name="'Inversión acumulada'" :id="'invest-gauge'" :color="'#00EBFF'" />
                    </template>
                </div>
            </div>
        </div>
        <div class="w-1/2 px-2">
            <div class="box mb-2 h-full mb-4">                                
                <div class="box-title flex items-center">Facturado</div>
                <div class="w-full relative h-92 xl:h-94">
                    <template v-if="loading">
                        <div class="w-full h-full flex justify-center items-center">
                            <img class="loader" src="/img/loader.svg" />
                        </div>
                    </template>
                    <template v-else>
                        <div class="absolute inset-0 flex flex-col justify-center items-center">
                            <span class="legend-xs">Acumulado</span>
                            <div class="text-white font-medium text-2xl xl:text-4xl leading-none border-b-2 border-area pb-3 mb-3">{{data.invoiced_percent | numFormat('0.0')}}<span class="symbol">%</span></div>
                            <span class="font-medium text-sm xl:text-lg leading-none text-white">{{data.invoiced| numFormat('0,0')}}€</span>                        
                        </div>
                        <GaugeChart :data="data.invoiced_percent" :pieOptions="pieOptions" :name="'Facturación acumulada'" :id="'invest-gauge'" :color="'#A279E2'" />
                    </template>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import GaugeChart from '@/components/charts/GaugeChart.vue';
export default {
    name: 'InvestmentPie',
    components: {
        GaugeChart,
    },
    props: ['data','loading'],
    data() {
        return {
            pieOptions: {
                width:'95%',
                classname: 'pie-chart',
                data: 25,
            },
        }
    },
    methods: {
        status(status) {
            return {
                'text-bad' : status > 0,
                'text-good' : status <= 0,
            }
        },
        investmentStatus(status) {
            return {
                'bg-bad shadow-light-bad': status > 0,
                'bg-good shadow-light-good': status <= 0,
            }
        }
    },
}
</script>

<style>

</style>