<template>

<transition v-if="window.width <= 1023" name="fade" mode="out-in" class="z-50">
        <div class="h-screen w-full px-8 md:px-56 text-center relative z-50 bg-dotted flex justify-center items-center">
            <div class="z-50 relative flex flex-col justify-center items-center w-full h-full">
                <img class="w-32 mb-10" src="/img/10tlogo.png" />
                <div class="font-bold text-white text-4xl"><span class="mdi mdi-information-outline mr-4"></span>Info</div>
                <span class="mdi mdi-phone-rotate-landscape mt-6 text-6xl text-ownColor"></span>
                <div class="text-white text-sm opacity-75">Si estás en una tablet, debes colocar el dispositivo en posición horizontal</div>
            </div>
        </div>
</transition>

<div v-else>
    <div class="w-screen h-full flex justify-between py-2 px-2 xl:px-4 bg-body -mx-2 xl:-mx-0 overflow-x-hidden">
        <All
            model="InvestmentControlInvestmentBilled"
            :key="'NavInvestment'"
            :immediate="true"
            :sensitive="true"
            :query="query"
            :data.sync="investment_pie">
        </All>
         <All
            model="Procedure"
            :key="'NavFormalities'"
            :immediate="true"
            :sensitive="true"
            :query="query"
            :data.sync="procedure">
        </All>
        <All
            model="BuildingGeneralBuilding"
            :key="'NavConstructionLotes'"
            :immediate="true"
            :sensitive="true"
            :query="query"
            :data.sync="construction_lotes">
        </All>
        <All
            model="BuildingGeneralKpis"
            :key="'NavConstructionKpi'"
            :immediate="true"
            :sensitive="true"
            :query="query"
            :data.sync="construction_kpi">
        </All>
        <div class="w-40 extra:w-56 xl:w-64 p-2 relative d">
            <ul class="fixed top-0 w-40 extra:w-56 xl:w-64 my-2">
                <li @click="investment('investment')">
                    <template >
                        <NavInvestment :data="investment_pie" />
                    </template>
                </li>
                <li @click="formalities('formalities')">
                    <template >
                        <NavFormalities :data="procedure" />
                    </template>
                </li>
                <li @click="construction('construction')">
                    <template >
                        <NavConstruction :data="construction_kpi" :lotes="construction_lotes" />
                    </template>
                </li>
                <div class="w-24 extra:w-56 xl:w-64 fixed left-0 bottom-0 flex flex-col justify-between leading-none">
                    <div class="w-full max-w-180">
                        <div class="flex items-center px-2 xl:px-4 py-2">
                            <img src="@/assets/img/logo-white.png" />
                        </div>
                    </div>
                    <div>
                        <div  v-if="user.data.role_id < 4" class="flex items-center  my-1 cursor-pointer px-2 xl:px-4 py-2 rounded-md" @click="back(user)">
                            <i class="w-3 xl:w-4 flex justify-center mdi mdi-laptop mr-2 text-md text-ownColor"></i>
                            <span class="text-xxs xl:text-xs text-grayText opacity-75">Admin</span>
                        </div>
                        <div class="flex items-center  my-1 cursor-pointer px-2 xl:px-4 py-2 rounded-md" @click="project(user)">
                            <i class="w-3 xl:w-4 flex justify-center mdi mdi-layers-triple-outline mr-2 text-md text-ownColor"></i>
                            <span class="text-xxs xl:text-xs text-grayText opacity-75">Proyectos</span>
                        </div>
                        <div class="flex items-center  my-1 cursor-pointer px-2 xl:px-4 py-2 rounded-md" @click="logout">
                            <i class="w-3 xl:w-4 flex justify-center mdi mdi-logout mr-2 text-md text-ownColor"></i>
                            <span class="text-xxs xl:text-xs text-grayText opacity-75">Logout</span>
                        </div>
                    </div>
                </div>
            </ul>
        </div>

        <div class="flex-1 min-w-0 pl-4 flex flex-col">
            <ProjectHeader :project="general" />
            <All
            model="General"
            :key="'General'"
            :immediate="true"
            :sensitive="true"
            :query="query"
            :data.sync="general"
             v-slot="{ loading }">
            </All>
            <router-view :project="general" />
        </div>
    </div>
</div>
</template>

<script>
    import ProjectHeader from '@/components/ProjectHeader.vue';
    import { state } from '@/store';
    import { All } from '@/api/components';
    import { Request } from '@/api/components';
    import { actions } from '@/store';
    import NavConstruction from '@/components/navigation/NavConstruction.vue';
    // import NavIncomes from '@/components/navigation/NavIncomes.vue';
    // import NavMaintenance from '@/components/navigation/NavMaintenance.vue';
    import NavInvestment from '@/components/navigation/NavInvestment.vue';
    import NavFormalities from '@/components/navigation/NavFormalities.vue';
    import { Auth } from '@/api/models';

    export default {
        name: 'Home',
        components: {
            All,
            ProjectHeader,
            Request,
            NavConstruction,
            // NavIncomes,
            // NavMaintenance,
            NavInvestment,
            NavFormalities,
        },
        data() {
            return {
                general: {},
                window: {
                    width: 0,
                    height: 0
                },
                investment_pie: {},
                procedure: {},
                construction_kpi: {},
                construction_lotes: {},
            }
        },
        computed: {
            user() {
                return state.user;
            },
            projectId() {
                return this.$route.query.id;
            },
            excelId() {
                return this.$route.query.excel;
            },
            query() {
                return {
                projectId: this.projectId,
                excelId: this.excelId
                }
            },
        },
        methods: {
            async logout(){
                await Auth.logout();
                await actions.resetAuth();
                this.$router.push({ name: 'login' });
            },
            async back(user) {
                switch(user.data.role_id) {
                case 1:
                    window.location.href = process.env.VUE_APP_BACK_URL_SUPER_ADMIN;
                    break;
                case 2:
                    window.location.href = process.env.VUE_APP_BACK_URL_PROMOTER_ADMINISTRATOR;
                    break;
                case 3:
                    window.location.href = process.env.VUE_APP_BACK_URL_PROJECT_DIRECTOR;
                    break;
                default:
                    this.$router.push({ name: 'Project' });
                }
            },
            async project(user) {
                this.$router.push({ name: 'Project' });
            },
            investment(dashboard){
                if(state.dashboard != 'investment') {
                    actions.setDashboard(dashboard);
                    this.$router.push({ name: 'Investment', query: { id: this.projectId, excel: this.excelId} });
                }
            },
            formalities(dashboard){
                if(state.dashboard != 'formalities') {
                    actions.setDashboard(dashboard);
                    this.$router.push({ name: 'Formalities', query: { id: this.projectId, excel: this.excelId} });
                }
            },
            construction(dashboard){
                if(state.dashboard != 'construction') {
                    actions.setDashboard(dashboard);
                    this.$router.push({ name: 'Construction', query: { id: this.projectId, excel: this.excelId} });
                }
            },
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
            },

        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();

        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
    }
</script>
