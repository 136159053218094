<template>
    <div class="w-screen h-screen">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "FullscreenLayout"
    }
</script>