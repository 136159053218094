<template>
    <div class="w-full flex items-center ml-2">
        <Ratio v-for="(ratio, index) in data.ratios" :key="index" :data="ratio" :dashboard="dashboard" />
    </div>
</template>

<script>
import Ratio from '@/components/Ratio.vue';
export default {
    name: 'RatiosContainer',
    components: {
        Ratio,
    },
    props: ['data','dashboard'],
}
</script>

<style>

</style>