<template>
    <div class="box mb-2 flex-no-grow xl:flex-grow"  style="max-height: 60vh;">
        <div class="box-title">Hitos</div>
        <div class="flex flex-col h-56 xl:h-64 overflow-auto flex-grow">
            <template v-if="loading">
                <div class="w-full h-full flex justify-center items-center">
                    <img class="loader" src="/img/loader.svg" />
                </div>
            </template>
            <template v-else>
                <div v-for="event in data.events" :key="event.id" class="bg-area rounded-md p-2 px-3 mb-2">
                    <div class="w-full flex flex-col xl:flex-row justify-between items-start mb-2">
                        <div class="w-auto mb-1 xl:mb-0 px-1 flex items-center rounded-xl border bg-opacity-25 font-medium" :class="priority(event.priority)">
                            <span class="text-xxs">{{event.priority}}</span>
                        </div>
                        <div class="w-full xl:w-auto flex-1 flex flex-col items-center xl:items-end justify-center">
                            <div class="w-full flex flex-col xl:flex-row justify-start xl:justify-end items-start xl:items-center leading-none my-1">
                                <div class="w-auto text-smallest xl:text-xs text-grayText text-left xl:text-right flex items-center"><span class="mdi mdi-calendar text-grayText text-sm mr-1"></span>{{event.date | moment("DD/MM/YY")}}</div>
                                <div class="w-auto text-smallest xl:text-xs text-grayText text-left xl:text-right flex items-center"><span class="mdi mdi-arrow-right text-grayText text-sm mx-1 opacity-50"></span>{{event.end_date | moment("DD/MM/YY")}}</div>
                            </div>
                            <div class="w-full text-smallest xl:text-xxs text-grayText text-left xl:text-right leading-none"><span class="text-white">{{event.deviation}}</span> días</div>
                        </div>
                    </div>
                    <div class="w-full f-between text-left leading-tight text-xxs xl:text-sm text-white">{{event.name}}</div>
                    <div class="w-full text-smallest xl:text-xxs text-grayText text-left leading-none mt-1" truncate>{{event.responsible}}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConstructionTimeline',
    props: ['data','loading'],
    data() {
        return {

        }
    },
    methods: {
        priority(priority) {
            return {
                'border-bad text-bad bg-bad' : priority == 'ALTA',
                'border-phase2 text-phase2 bg-phase2' : priority == 'MEDIA',
                'border-phase1 text-phase1 bg-phase1' : priority == 'BAJA',
            }
        }
    }
}
</script>

<style>

</style>